.productsDecoration {
    display: flex;
    align-items:center;
    height:25vw;
    width: 100%;
    position: relative;
}

.productsWrap {
    width: 80%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 70vh;

}

.products-nav-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 8rem;
}

.products-nav-link {
    text-decoration: none;
    color: black;
    font-family: 'Baloo Tamma 2', cursive;
    margin: 5rem 0 5rem 0;
    font-size: 1.5rem;

    
}

.products-nav-link.active {
    text-decoration:underline;
}


@media (max-width: 1100px) {
    
  .products-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap:0.5rem; 
     min-height: 40vh;
     margin-bottom: 2rem;
     margin-top: 5rem;

}

}


@media (max-width: 700px) {
    .productsWrap {
        width: 100vw;
        height: max-content;
    }

    .productsDecoration {
        height:40vh;
        width: 95%;
        margin: 0 auto;

  }

  .products-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap:0.5rem; 
     min-height: 40vh;
     margin-bottom: 2rem;
     margin-top: 5rem;

}

.products-nav-link {
    text-decoration: none;
    color: black;
    margin: 0 auto;
    font-size: 1.5rem;
}


}


@media (max-width: 1100px) {
    .products-nav-links {
        display: flex;
        flex-direction: column;
    }

    .homeLogo {
        width: 14rem;
    }
}