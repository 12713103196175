.categoryWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Baloo Tamma 2', cursive;
    height: 84vh;
}

.description {
    width: 50%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y:hidden;  
    white-space: pre-line;  
    vertical-align: bottom;
  }

  
  .gallery {
    width: 50%;
    height:84vh;
    display: flex;
    align-items:center;
    position: sticky;
    top: 0;
    align-self: flex-start;
  }

  
.categoryText {
  text-align: left;
  max-width: 80%;
  line-height: 2rem;
  font-size: 1.3rem;
  margin: 1.5rem;
  color:rgb(27, 27, 27);
}



@media (max-width: 1100px) {
  .categoryWrap {
    width: 100vw;
    height: max-content;
    flex-direction: column-reverse;
    gap:5rem;
    overflow-x: hidden;
    margin:0 auto;
}

  .gallery {
    width: 95%;
    margin: 0 auto;
    height: 40vh;
  }

  .bgimg {
    margin-top: 2rem;
    height: 100%;
  }


  .centerLogo  {
    width: 14rem;
    position: absolute;
    top:auto;
    right: auto;
    bottom: 0;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 50%);
  }

  .description {
    width: 80%;
    gap:1rem;
    align-items: center;
  }

  .categoryText {
    max-width: 100%;
    text-align: center;
    font-size: 1rem;

  }
}
