.productCoursel {
    max-width: 40vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    align-self: flex-start;

  }


  .card  {
    height: 27rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-bottom: 2rem;

  }

  
.productImage {
    max-height: 25rem;
    max-width: 20rem;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 6px 9px 9px;
    pointer-events: none;
}


@media (max-width: 500px) {
  .productCoursel {
    max-width: 100vw;
    margin: 0 auto;
  }

}

@media (min-width: 501px) and (max-width: 1100px) {
  .productCoursel {
      width: 50%;}

       
.productImage {
  width: 90%;
}

}

@media (min-width: 1101px) and (max-width: 1550px) {
  .productImage {
    max-width: 30rem;
  }

}