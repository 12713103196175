.background {
  background-color: white;
}

.title {
  color: black;
  font-size: 3.5rem;
  font-family: 'Shadows Into Light Two', cursive;
  letter-spacing: 8px;
  text-align: center;
}
.wrapper {
  margin: 0 auto;
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}

.imgCon {
  display: flex;
  align-items: center;
  height: 25vw;
  width: 100%;
  position: relative;
}

.textSubtitle {
  color: black;
  font-size: 2rem;
  font-family: 'Baloo Tamma 2', cursive;
  letter-spacing: 3px;
  text-align: center;
}

.homeSubtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  margin-top: 3rem;
  white-space: nowrap;
}

.middleTitle {
  margin: 0 auto;
}

.bgimages {
  height: 95%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 6px 9px 9px;
  pointer-events: none;
}

.homeLogo {
  width: 20rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, 50%);
}

.home-nav-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
}

.home-nav-link {
  text-decoration: none;
  color: black;
  font-family: 'Baloo Tamma 2', cursive;
  letter-spacing: 2px;
  margin: none;
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden;
}

.home-nav-link.active {
  text-decoration: underline;
}

@media (max-width: 700px) {
  .wrapper {
    width: 100vw;
    height: max-content;
    overflow-x: hidden;
  }

  .title {
    font-size: 2rem;
  }

  .textSubtitle {
    font-size: 1.4rem;
  }

  .imgCon {
    height: 30vh;
    width: 95%;
  }

  .home-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    margin-top: 3rem;
  }

  .home-nav-link {
    text-decoration: none;
    color: black;
    margin: 0 auto;
    font-size: 1.5rem;
  }
}

@media (max-width: 1100px) and (min-width: 701px) {
  .wrapper {
    width: 100vw;
    height: max-content;
    overflow-x: hidden;

  }

  .imgCon {
    height: 30vw;
    width: 95%;
  }

  .title {
    font-size: 2rem;
  }

  .textSubtitle {
    font-size: 1.4rem;
  }

  .home-nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    margin-top: 3rem;}

  .home-nav-link {
    text-decoration: none;
    color: black;
   margin: 0 auto;
    font-size: 1.5rem;
  }
}
