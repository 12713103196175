.footerCon {
    width: 80%;   
    border-top:  1px solid black;
    margin:0 auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:2rem;
    align-items: center;
}

.subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    letter-spacing: 5px;
    font-size: 1.5rem;
    font-family: 'Sniglet', cursive;
    color: rgba(66, 66, 66, 0.685);


}

.footerWrap{
    width: 100%;
}
.dividerBottom {
    color: black;
    width: 70%;
    
}

.footerLeftCol {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:4rem;
    align-items: center;
    font-family: 'Baloo Tamma 2', cursive;
    font-size: 1rem;
    box-sizing: border-box;

}

.footer-nav-link {
    text-decoration: none;
    color: rgba(66, 66, 66, 0.486);
    font-family: 'Baloo Tamma 2', cursive;

    font-size: 1rem;
}

.footer-nav-link:hover {
    color: rgba(27, 27, 27, 0.877);

}

.footerLinks {
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: center;
    margin: 0 auto;

}

@media (max-width: 1000px) {


    .subtitle {
        font-size: 0.6rem;
        text-align: center;
    }

    .footer-nav-link {
        font-size: 0.7rem;

    }

}