.galleryWrap {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 84vh;
        width: 80%;
}

.galleryCoursel {
    max-width: 95vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    margin-top: 2rem;
    overflow: hidden;

  }


  .galleryCard  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding-bottom: 2rem;
    pointer-events: none;


  }

  
.galleryImage {
    max-height: 80vh;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 6px 9px 9px;
    pointer-events: none;
}

.splide:not(.is-overflow) .splide__pagination {
    display: flex;
}


.splide__pagination__page.is-active {
  background: black !important;
  opacity: 1  !important;
  transform: scale(1.2)  !important;
}


@media (max-width: 1100px) {
  .galleryWrap {
    height: fit-content;
}

}