.bar {
    background-color: white;
    width:80%;
    height: 8vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding: 1rem;
    font-size:1.5rem;
    border-bottom: 1px solid black;
    box-sizing: border-box;

}

.logoContainer {
    display: flex;
    justify-items: flex-start;
    flex-direction: row;
    align-items: center;
    gap:1rem;
    margin-right: 2rem;
    cursor: pointer;
}


.logoNavBar {
    height: 2.5rem;
}

.menuIcon{
    height: 2rem;
}

.menuContainer {
    display: flex;
    justify-items: flex-start;
    flex-direction: row;
    align-items: center;
    gap:1rem;
    margin-left: 2rem;
    cursor: pointer;

    
}

.navWrap {
    width: 100%;

}

.togglerCon {
display: flex;
justify-items: flex-start;
flex-direction: row;
align-items: center;
gap:1rem;
}

.nav-link {
    text-decoration: none;
    color: black;
    font-family: 'Baloo Tamma 2', cursive;    margin: 0.5rem;
    
}

a {
    text-decoration: none;
}

.menuLink {
    text-decoration: none;
    color: black;
    font-family: 'Baloo Tamma 2', cursive;
    display: flex;

}

p {
    margin: 0;
}


.subNav {
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 1rem;
}

.pageTitle {
    text-decoration: none;
    color: black;
    font-family: 'Baloo Tamma 2', cursive;    font-size: 2.2rem;
    text-align: center;
    margin: 0 auto;
}

@media (max-width: 700px) {
.bar {
    height: 10vh;
    width: 90%;
    font-size: 1rem;
}

.logoNavBar {
    height: 2rem;
}

.menuIcon{
    height: 1.8rem;
}

}


@media (max-height: 600px) {
    .logoNavBar {
        height: 1.5rem;
    }

}