.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    gap: 2rem;
    width: 60%;
    margin: 0 auto;
    font-size: 1.4rem;
}

.nav-link {
    text-decoration: none;
    color: black;
    font-family: 'Baloo Tamma 2', cursive;
    overflow-y: hidden;
    height: fit-content;

}


.linkCon {
    position: relative
}

.btn-one {
  font-family: 'Baloo Tamma 2', cursive;
  color: black;
    transition: all 0.3s;
    position: relative;
  }
  .btn-one span {
    transition: all 0.3s;
  }
  .btn-one::before {
    content: '';
    position: absolute;
    bottom: -1;
    left: -5%;
    margin: 0 auto;
    width: 110%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0);
    transform: scale(0.1, 1);
  }
  .btn-one:hover span {
    letter-spacing: 2px;
  }
  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255,255,255,0.1);
  }
  .btn-one:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }

  .btn-one.active::before {
    content: '';
    position: absolute;
    bottom: -1;
    left: -5%;
    margin: 0 auto;
    width: 110%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0);
    transform: scale(1);
  }

.nav-link p {
    margin: 0;
}




@media (max-width: 1100px) {
  .nav-links {
      gap: 2rem;
      font-size: 1.3rem;
      white-space: nowrap;
      width: 100%;
  }

}

.btn-one {

  font-size: 1.3rem;

}




@media (max-width: 500px) {
    .nav-links {
        gap: 1rem;
        font-size: 1.2rem;
        white-space: nowrap;

    }

    
    .btn-one{

  font-size: 1rem;

}



}