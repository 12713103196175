.product {
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.productCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.productTitle {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap:3rem;
  text-align: center;
  font-family: 'Baloo Tamma 2', cursive;
  font-size: 3rem;
  grid-area: title;
}

.productDescription {
  text-align: left;
  width: 70%;
  line-height: 2rem;
  font-size: 1.3rem;
  margin: 1.5rem;
  color: rgb(43, 43, 43);
  font-family: 'Baloo Tamma 2', cursive;
  white-space: pre-line;
  vertical-align: bottom;
}

.productText {
  width: 50%;
}

.productGallery {
  max-width: 50%;
}


@media (max-width: 500px) {
  .productCon {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .productText {
    width: 100%;
    font-size: 0.8rem;
  }
  .productTitle {
    font-size: 2rem;
  }

  .productDescription {
    text-align: center;
    margin:0 auto;
    font-size: 1rem;
  }
  .productGallery {
    max-width: 100%;
  }


}


@media (min-width: 501px) and (max-width: 1100px) {
  .productCon {
    flex-direction: row;
    width: 100%;
    text-align: center;
  }

  .productText {
    width: 50%;
    font-size: 0.8rem;
  }
  .productTitle {
    font-size: 2rem;
  }

  .productDescription {
    text-align: center;
    margin:0 auto;
    font-size: 1rem;
  }
  .productGallery {
    max-width: 50%;
  }


}