.contactWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Baloo Tamma 2', cursive;
  height: auto;
  width: 80%;
  margin: 0 auto;

}

.contactRightCol {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  overflow-y: hidden;
  padding: 1rem;
}

.pageName {
  margin: 2.5rem;
  font-size: 2.5rem;
}

.contactText {
  text-align: left;
  max-width: 70%;
  line-height: 2rem;
  font-size: 1.7rem;
  margin: 1rem;
  color: rgb(27, 27, 27);
  height: fit-content;

}

.contactLeftCol {
  width: 50%;
  height: 84vh;
  display: flex;
  align-items: center;
  position: relative;
}

.bgimg {
  height: 90%;
  width: 100%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 6px 9px 9px;
}

.contactCenterLogo {
  width: 20rem;
  position: absolute;
  top: 50%;
  right: 0;
  /* bring your own prefixes */
  transform: translate(50%, -50%);
}

.contactCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;

}

.contactIconWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1100px) {
  .contactWrap {
    width: 100vw;
    flex-direction: column;
    gap:3rem;
    overflow-x: hidden;
    margin:0 auto;
}


  .contactLeftCol {
    width: 95%;
    margin: 0 auto;
    height: 40vh;
  }

  .contactText {
    font-size: 1.5rem;

  }

  

  .bgimg {
    margin-top: 2rem;
    height: 100%;
  }


  .contactCenterLogo {
    width: 14rem;
    position: absolute;
    top:auto;
    right: auto;
    bottom: 0;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 50%);
  }

  .contactRightCol {
    width: 100%;
    gap:5rem;
    margin-bottom:3rem ;
    height: fit-content;  
    padding-bottom:4rem;

  }
}
