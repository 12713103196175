.benefitsAccordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  line-height: 1rem;
  font-size: 1.3rem;
  color: rgb(27, 27, 27);
  font-family: 'Baloo Tamma 2', cursive;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.benefitsAccordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  cursor: pointer;
}

.benefitsAccordionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
  white-space: nowrap;
}

@media (max-width: 1100px) {
  .benefitsAccordion {
    margin: 0 auto;
    width: 50%;
  }

  .benefitsAccordionTitle {
    font-size: 1.1rem;
  }

  .benefitsAccordionContent {
    font-size: 1rem;
  }
}
