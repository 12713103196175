.aboutWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Baloo Tamma 2', cursive;
    height: auto;
  width: 80%;
  margin: 0 auto;

}

.leftCol {
  width: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow:hidden;


}



.aboutText {
  text-align: left;
  max-width: 80%;
  line-height: 2rem;
  font-size: 1.3rem;
  margin: 1.5rem;
  color:rgb(27, 27, 27);
}

.rightCol {
  width: 50%;
  height:84vh;
  display: flex;
  align-items:center;
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.bgimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 6px 9px 9px;
  pointer-events: none;
}

.centerLogo {
  width: 20rem;
  position: absolute;
  top: 50%;
  left: 0;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  z-index: 10;

}

hr {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.781), rgba(0, 0, 0, 0));
  background-color: white;
  padding: 0;
}



@media (max-width: 1100px) {
  .aboutWrap {
    width: 100vw;
    height: max-content;
    flex-direction: column-reverse;
    gap:3rem;
    overflow-x: hidden;
    margin:0 auto;
}

  .rightCol {
    width: 95%;
    margin: 0 auto;
    height: 40vh;
  }

  .bgimg {
    margin-top: 2rem;
    height: 100%;
  }


  .centerLogo  {
    width: 20rem;
    position: absolute;
    top:auto;
    right: auto;
    bottom: 0;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, 50%);
  }

  .leftCol {
    width: 100%;
    gap:1rem;
    margin-bottom:3rem ;
  }
}
